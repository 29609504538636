import React from 'react'
import { profileImg } from '../../../Utils/constants'

const Employees = () => {
  const leaderShipDetails = [
    {
      name: "Alekhya Vishnu",
      qualification: "Software Developer, MS",
      description: `Software developer with 4 years of experience in the IT industry. My expertise spans MERN stack development, MuleSoft integration, and cloud solutions, particularly with AWS.
      Recently completed my master’s in Computer Science at the University of Texas at Arlington.`,
      image: profileImg,
    },
    {
      name: "Yajvanth Alpuri",
      qualification: "Software Developer, MS",
      description: `Lorem ipsum odor amet, consectetuer adipiscing elit. Pellentesque vel
                    eu rutrum nulla pulvinar inceptos quis id pharetra. Laoreet malesuada
                    parturient fusce curae facilisis phasellus leo. In consectetur libero
                    risus etiam euismod luctus, cubilia molestie. Varius suscipit nibh mi
                    inceptos blandit parturient erat.`,
      image: profileImg,
    },
  ];
  return (
    <div>
      <div className="py-4 space-y-6">
        <p className="md:text-center md:px-28 lg:px-20">
          At Arka Tech Solutions, our diverse team of innovators and
          problem-solvers is at the core of our success. With expertise across
          various disciplines, we work together to deliver cutting-edge
          solutions that exceed client expectations. We foster a culture of
          inclusivity, creativity, and continuous learning, encouraging our
          employees to take ownership and push boundaries in their work.
          Discover the talent and passion behind the transformative solutions we
          create and explore the stories of our exceptional team.
        </p>
        {/* <div className="w-full h-96 flex">
          <img src={bgImg} alt="team-img" className="w-full object-cover" />
        </div> */}
        <div className="p-4 space-y-6 justify-center">
      {leaderShipDetails.map((leader, index) => (
        <div key={index} className="md:flex md:space-x-6">
          <div className="md:w-[15%] h-[15%]">
            <img src={leader.image} alt={`${leader.name}-img`} className="object-cover w-full h-full rounded-lg" />
          </div>
          <div className="md:w-[75%] md:h-[250px] p-4 space-y-4 shadow-lg rounded-lg">
            <p className="text-xl font-semibold">{leader.name}</p>
            <p className="text-sm text-gray-500">{leader.qualification}</p>
            <p>{leader.description}</p>
          </div>
        </div>
      ))}
    </div>
      </div>
    </div>
  );
}

export default Employees