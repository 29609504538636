import { email_regex, mobile_regex } from "../constants";

export const validateDemoForm = (fName, lName, email, mobile, date, time, purpose) => {
    const error ={
        fName: '',
        lName: '',
        email: '',
        mobile: '',
        date: '',
        time: '',
        purpose: ''
    }

    if(!fName){
        error.fName= "Please enter first name";
    }
    if(!email){
        error.email = "Email field cannot be empty";
    } else if(!email_regex.test(email)){
        error.email = "Please enter valid email address";
    }
    if(!mobile_regex.test(mobile)){
        error.mobile = "Please enter valid mobile number";
    }
    if(!date) {
        error.date = "Please select a date to schedule demo"
    }
    if(!time){
        error.time = "Please enter the time to schedule demo"
    }
    if(!purpose){
        error.purpose = "Please enter the purpose for booking a demo"
    }
    return error;
}

