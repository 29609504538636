import React, { useRef, useState } from 'react'
import { addDemoDetailsAPI } from '../../Utils/constants';
import { validateDemoForm } from '../../Utils/Validations/ValidateDemoForm';

const DemoForm = () => {
    const fnameRef = useRef(null);
    const lnameRef = useRef(null);
    const emailRef = useRef(null);
    const mobileRef = useRef(null);
    const dateRef = useRef(null);
    const timeRef = useRef(null);
    const purposeRef = useRef(null);

    const[errors, setErrors] = useState({
      firstName:'',
      lastName:'',
      email:'',
      mobile: '',
      date: '',
      time: '',
      purpose: ''
    });

    const[loading, setLoading] = useState(false);

    const submitForm = async (e) => {
        e.preventDefault();
        const demoFormData ={
          firstName: fnameRef.current.value,
          lastName: lnameRef.current.value,
          email: emailRef.current.value,
          mobile: mobileRef.current.value,
          date: dateRef.current.value,
          time: timeRef.current.value,
          purpose: purposeRef.current.value, 
        }
        const validateErrors = validateDemoForm(demoFormData.firstName, demoFormData.lastName, demoFormData.email, demoFormData.mobile, demoFormData.date, demoFormData.time, demoFormData.purpose);
        setErrors(validateErrors);
        console.log(validateErrors, demoFormData);
        if (Object.values(validateErrors).every((error) => error === "")) {
          setLoading(true);
          try {
            const response = await fetch(addDemoDetailsAPI, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(demoFormData),
            });
    
            const data = await response.json();
    
            if (response.ok) {
              alert(data.message);
              fnameRef.current.value = "";
              lnameRef.current.value = "";
              emailRef.current.value = "";
              mobileRef.current.value = "";
              dateRef.current.value = "";
              timeRef.current.value = "";
              purposeRef.current.value = "";
            } else {
              alert(
                "There was an error submitting your details. Please try again."
              );
            }
          } catch (error) {
            console.error("Error:", error);
            alert("There was an error submitting your details. Please try again.");
          } finally {
            setLoading(false);
          }
        }
    }

    
  return (
    <div className="">
      <div className="m-8 shadow-xl md:my-20 rounded-xl text-center">
        <h1 className='font-bold text-black text-2xl md:text-3xl py-4'>Schedule a Demo</h1>
        <form className='pb-8' onSubmit={submitForm}>
          <div className='py-4'>
            <input type="text" ref={fnameRef} placeholder="First Name" className='border border-gray-600 rounded-lg h-10 w-[70%] pl-4'/>
            {errors?.firstName && <p className='text-red-500 pt-2 text-lg'>{errors.firstName}</p>}
          </div>
          <div className='py-4'>
            <input type="text" ref={lnameRef} placeholder="Last Name" className='border border-gray-600 rounded-lg h-10 w-[70%] pl-4'/>
            {errors.lastName && <p className='text-red-500 pt-2 text-lg'>{errors.lastName}</p>}
          </div>
          <div className='py-4'>
            <input type="text" ref={emailRef} placeholder="Email" className='border border-gray-600 rounded-lg h-10 w-[70%] pl-4'/>
            {errors.email && <p className='text-red-500 pt-2 text-lg'>{errors.email}</p>}
          </div>
          <div className='py-4'>
            <input type="text" ref={mobileRef} placeholder="Phone Number" className='border border-gray-600 rounded-lg h-10 w-[70%] pl-4'/>
            {errors.mobile && <p className='text-red-500 pt-2 text-lg'>{errors.mobile}</p>}
          </div>
          <div className='space-x-3 md:space-x-6 py-4'>
            <input type="date" ref={dateRef} placeholder="Select Date" className='border border-gray-600 rounded-lg h-10 w-[33%] px-2 cursor-text'/>
            <input type="time" ref={timeRef} placeholder="Select time" className='border border-gray-600 rounded-lg h-10 w-[33%] px-2 cursor-text'/>
            {errors.date && <p className='text-red-500 pt-2 text-lg'>{errors.date}</p>}
            {errors?.time && <p className='text-red-500 pt-2 text-lg'>{errors.time}</p>}
          </div>
          <div className='py-4'>
            <input type="text" ref={purposeRef} placeholder="Purpose" className='border border-gray-600 rounded-lg h-10 w-[70%] pl-4'/>
            {errors?.purpose && <p className='text-red-500 pt-2 text-lg'>{errors.purpose}</p>}
          </div>
          <div className='py-4'>
            <button
              className={`w-[70%] h-10 bg-yellow-500 rounded-lg ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
              disabled={loading}
            >
              {loading ? (
                <span>Submitting...</span>
              ) : (
                'Book'
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default DemoForm