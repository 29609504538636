import React from "react";
import ContactForm from "./ContactForm";
import { facebookIcon, instaIcon, linkedinIcon, twitterIcon } from "../../Utils/constants";

const ContactUS = () => {
  return (
    <div className="md:flex px-2 md:px-10 md:h-screen items-center justify-center py-8 md:py-0">
      <div className="md:w-[50%] m-4 px-4">
        <h1 className="font-bold text-2xl md:text-4xl text-black">
          Get In Touch With Us
        </h1>
        <p className="text-lg md:text-xl mt-4 md:mt-12">
          We’re here to help! We put the “Human” in Human Resources with
          world-class support......
        </p>
        <div className="my-6 md:my-20 rounded-xl shadow-lg">
          <div className="p-4">
            <p className="text-xl md:text-2xl text-slate-600 font-bold">Contact</p>
            <p className="text-lg py-2 text-gray-600">
              📧
              <a href="mailto:developer@arkatechsol.com">
                developer@arkatechsol.com
              </a>
            </p>
            <p className="text-lg py-2 text-gray-600">☎️+1(425)663-1723 </p>
            <p className="text-xl md:text-2xl text-slate-600 font-bold py-4">Address</p>
            <p className="text-lg text-gray-600 flex flex-col">
              <a
                href="https://www.google.com/maps/search/?api=1&query=24003+140th+Ave+SE+Kent,+WA+98042"
                target="_blank"
                rel="noreferrer"
              >
                <span>24003 140th Ave SE </span>
                <span>Kent, WA 98042 </span>
              </a>
            </p>
            <div className="flex space-x-3 pt-6">
              {twitterIcon}
              {linkedinIcon}
              {instaIcon}
              {facebookIcon}
            </div>
          </div>
        </div>
      </div>
      <div className="md:w-[60%]">
        <ContactForm />
      </div>
    </div>
  );
};

export default ContactUS;
