import React from "react";
import { appStoreLink, comingSoonImg, handbukLogo, playStoreLink } from "../../../../Utils/constants";

const Home = () => {
  const handleRedirect = (url) => {
    window.open(url, "_blank");
  };
  return (
    <div className="w-full md:flex">
      <div className="md:w-[50%] m-2 md:my-8">
        <h1 className="font-bold text-2xl md:text-4xl text-slate-600 flex flex-col">
          <span className="text-customGreen pb-2 flex">
            <img src={handbukLogo} alt="handBuk-logo" className="w-10" />
            <span className="pl-3">HandBuk</span>{" "}
          </span>
          <span className="text-slate-600 text-sm md:text-xl">
            Empowering Businesses through Streamlined Financial Management
          </span>
        </h1>
        <p className="text-sm md:text-xl mt-3 md:mt-8 text-justify">
          HandBuk is a business management mobile application that digitizes
          traditional bookkeeping. Users can track transactions, manage
          inventory, and handle vendor relationships. The app’s value lies in
          time savings, accuracy, accessibility, efficiency, and compliance with
          financial regulations.
        </p>
        <div className="flex space-x-4 mt-5 md:mt-10">
          <img
            src="https://inhandbook.com/wp-content/uploads/2020/09/App-Store-Download.png"
            alt="donwload-logo"
            className="w-40 md:w-52 h-20 md:h-24 cursor-pointer transition-transform duration-300 transform hover:scale-105 hover:brightness-125"
            onClick={() => handleRedirect(appStoreLink)}
          />
          <img
            src="https://inhandbook.com/wp-content/uploads/2020/09/Google-Play-Download.png"
            alt="download-logo"
            className="w-40 md:w-52 h-20 md:h-24 cursor-pointer transition-transform duration-300 transform hover:scale-105 hover:brightness-125"
            onClick={() => handleRedirect(playStoreLink)}
          />
        </div>
      </div>
      <div className="md:w-[50%]">
        <div className="w-full flex pt-14 px-6">
          <div className="w-screen aspect-video bg-gradient-to-t from-black flex items-center justify-center">
            <img
              src={comingSoonImg}
              alt="Coming Soon Thumbnail"
              className="w-full h-full object-cover  bg-slate-500"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
