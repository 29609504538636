import React from 'react'
import Header from './Header'
import Footer from './Footer'
import { Outlet } from 'react-router-dom';

const LandingPage = () => {
  return (
    <div className="h-screen items-center justify-center">
      <Header />
      <Outlet />
      <Footer />
    </div>
  );
}

export default LandingPage;