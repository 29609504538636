import { Tabs } from '@cloudscape-design/components'
import React from 'react';
import Employees from "./Employees";
import ComingSoon from "../../../Components/ComingSoon";
import LeaderShip from './LeaderShip';
import Locations from './Locations';

const AboutUSTabs = () => {
  return (
    <div className="m-8 md:px-4">
        <Tabs
        tabs={[
          {
            label: "Leadership",
            id: "first",
            content: <LeaderShip />,
          },
          {
            label: "Employees",
            id: "second",
            content: <Employees />,
          },
          {
            label: "Locations",
            id: "thrid",
            content: <Locations />,
          },
          {
            label: "Certifications",
            id: "fourth",
            content: <ComingSoon />,
          },
          // {
          //   label: "News & Media",
          //   id: "fivth",
          //   content: <MediaUpdates />,
          // },
        ]}
      />
    </div>
  )
}

export default AboutUSTabs;
