import React from 'react'
import DemoForm from './DemoForm'
import { demoImg } from '../../Utils/constants'

const BookDemo = () => {
  return (
    <div className="md:flex items-center justify-center px-2 md:px-10 lg:h-screen">
      <div className="md:w-[50%] m-4 px-4">
        <h1 className="font-bold text-2xl md:text-4xl text-black pt-4">
          Book a Demo with us
        </h1>
        <p className="text-lg md:text-xl py-4">
          We’re here to help!
        </p>
        <div className='py-4'>
          <img src={demoImg} alt="planning-Demo" />
        </div>
      </div>
      <div className="md:w-[50%]">
        <DemoForm />
      </div>
    </div>
  )
}

export default BookDemo