import React from 'react'
import Tabs from "@cloudscape-design/components/tabs";
import HandBukHome from "./Pages/HandBukHome";
import HowItWorks from './Pages/HowItWorks';
import Features from './Pages/Features';

const Main = () => {
  return (
    <div className="m-6 md:m-8 md:px-4 h-auto md:h-screen">
      <Tabs
        tabs={[
          {
            label: "Home",
            id: "first",
            content: <HandBukHome />,
          },
          {
            label: "How it works",
            id: "second",
            content: <HowItWorks />,
          },
          {
            label: "HandBuk-Features",
            id: "thrid",
            content: <Features />,
          }
        ]}
      />
    </div>
  )
}

export default Main