import React from "react";
import servicesBG from "../Utils/Assets/services-bg.jpg";
import { BI, CSD, DV, MAD, MLD, WD } from "../Utils/constants";

const Services = () => {
  const postDetails = [
    {
      image: CSD,
      alt: "post-img",
      postName: "Custom Software Development",
      description:
        "We specialize in building tailor-made software solutions that align perfectly with your business needs. Whether it's automating processes, integrating systems, or creating entirely new platforms, our team of experts will deliver software that improves efficiency and drives business success. Every solution is designed to be scalable, secure, and adaptable to your growing requirements. ",
    },
    {
      image: MAD,
      alt: "post-img",
      postName: "Mobile Application Development",
      description:
        "Streamline business processes and engage your audience through custom-built mobile applications. We design and develop user-friendly, high-performance apps tailored to your business objectives. From enterprise applications to consumer-facing solutions, we ensure seamless integration with systems and provide ongoing support to keep your app running smoothly.",
    },
    {
      image: BI,
      alt: "post-img",
      postName: "Business Intelligence",
      description:
        "Unlock the potential of your data to make informed business decisions. Our business intelligence solutions provide a comprehensive view of your operations by integrating data from multiple sources, delivering insights that can drive strategy and improve operational performance. With predictive analytics, KPI tracking, and real-time reporting, we help you stay ahead in a competitive market. ",
    },
    {
      image: MLD,
      alt: "post-img",
      postName: "Machine Learning",
      description:
        "Leverage the power of machine learning to make accurate predictions and assessments. From forecasting future revenue to assessing risks and customer behaviour, our machine learning models are designed to help you make smarter, faster decisions. Whether you're looking to optimize marketing campaigns or improve operational efficiencies, we offer customized models that adapt to your specific business context. ",
    },
    {
      image: WD,
      alt: "post-img",
      postName: "Web Development",
      description:
        "Build robust, scalable, and user-friendly websites that enhance your online presence. Our team specializes in full stack development, creating responsive websites optimized for performance, security, and search engine rankings. Whether you need a corporate site or web application, we deliver solutions that combine aesthetics with functionality. ",
    },
    {
      image: DV,
      alt: "post-img",
      postName: "Data Visualization",
      description:
        "Turn complex data into actionable insights through powerful visualizations. We create interactive dashboards and reports that simplify the understanding of data patterns, trends, and outliers, empowering your team to make data-driven decisions. From graphs to heat maps, we ensure that your data tells a compelling story. ",
    },
  ];
  return (
    <div className="h-auto flex flex-col items-center">
      <div className="w-full h-[300px] relative">
        <img src={servicesBG} alt="serviceBG" className="w-full h-full object-cover"/>
      </div>
      <p className="absolute pt-32 text-center font-semibold text-4xl">Our Services</p>
      <div className="flex flex-wrap justify-center py-4">
        {postDetails.map((post) => (
          <div key={post.name} className="md:w-[25%]">
            <div className="m-4 px-4">
              <img src={post.image} alt="ourMission-img" className="" />
              <div className="shadow-xl p-6 text-justify bg-slate-100">
                <p className="font-semibold text-3xl pb-4 text-center">{post.postName}</p>
                <p>{post.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Services;
