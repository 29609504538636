import React from 'react';
import { profileImg } from '../../../Utils/constants';

const LeaderShip = () => {
  const leaderShipDetails = [
    {
      name: "Aneela Kamasani",
      qualification: "CEO",
      description: `Lorem ipsum odor amet, consectetuer adipiscing elit. Pellentesque vel
                    eu rutrum nulla pulvinar inceptos quis id pharetra. Laoreet malesuada
                    parturient fusce curae facilisis phasellus leo. In consectetur libero
                    risus etiam euismod luctus, cubilia molestie. Varius suscipit nibh mi
                    inceptos blandit parturient erat.`,
      image: profileImg,
    },
    {
      name: "Rahul Reddy",
      qualification: "Client Manager, MS",
      description: `Lorem ipsum odor amet, consectetuer adipiscing elit. Pellentesque vel
                    eu rutrum nulla pulvinar inceptos quis id pharetra. Laoreet malesuada
                    parturient fusce curae facilisis phasellus leo. In consectetur libero
                    risus etiam euismod luctus, cubilia molestie. Varius suscipit nibh mi
                    inceptos blandit parturient erat.`,
      image: profileImg,
    },
    {
      name: "Varun V",
      qualification: "Lead Architect, MS",
      description: `Lorem ipsum odor amet, consectetuer adipiscing elit. Pellentesque vel
                    eu rutrum nulla pulvinar inceptos quis id pharetra. Laoreet malesuada
                    parturient fusce curae facilisis phasellus leo. In consectetur libero
                    risus etiam euismod luctus, cubilia molestie. Varius suscipit nibh mi
                    inceptos blandit parturient erat.`,
      image: profileImg,
    },
  ];

  return (
    <div className="p-4 space-y-6 justify-center">
      {leaderShipDetails.map((leader, index) => (
        <div key={index} className="md:flex md:space-x-6">
          <div className="md:w-[15%] h-[15%]">
            <img src={leader.image} alt={`${leader.name}-img`} className="object-cover w-full h-full rounded-lg" />
          </div>
          <div className="md:w-[75%] md:h-[250px] p-4 space-y-4 shadow-lg rounded-lg">
            <p className="text-xl font-semibold">{leader.name}</p>
            <p className="text-sm text-gray-500">{leader.qualification}</p>
            <p>{leader.description}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default LeaderShip;
