import React from 'react'
import comingSoonImg from "../Utils/Assets/comingSoon.png"

const ComingSoon = () => {
  return (
    <div className='bg-slate-600 text-white space-y-10 h-screen w-full flex flex-col items-center justify-center'>
      <div className="flex items-center justify-center">
        <img
          src={comingSoonImg}
          alt="Coming Soon"
          className="w-[70%] object-contain"
        />
      </div>
      <div className='text-center space-y-4'>
        <h1 className='text-2xl font-semibold'>Coming Soon</h1>
        <p>This page is currently in development, but be sure to check back for updates!</p>
      </div>
    </div>
  );
}

export default ComingSoon;