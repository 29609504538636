import React from "react";
import { ourValues, vision, mission } from "../../Utils/constants";
import AboutUSTabs from "./AboutUSComponents/AboutUSTabs";

const AboutUS = () => {
  return (
    <div>
      <div className="py-8 px-8 md:px-16">
        <p className="text-3xl font-semibold text-center">About us</p>
        <p className="py-4 text-justify">
          Welcome to Arka Tech Solution, a proudly women-owned, product-based
          software company dedicated to innovation and excellence. At Arka, we
          specialize in building cutting-edge products that leverage the latest
          technologies to meet the ever-evolving needs of our clients. Our team
          is passionate about creating custom software solutions that are not
          only powerful but also tailored to the unique requirements of each
          project.
        </p>
        <p className="text-justify">
          With a focus on quality and client satisfaction, we strive to deliver
          products that drive success and add value to businesses across various
          industries. Whether you're looking for a complete end-to-end solution
          or a custom-built product designed to solve specific challenges, Arka
          Tech Solution is here to turn your vision into reality.
        </p>
      </div>
      <div className="md:flex px-8 md:px-12 md:space-x-16 lg:space-x-8 pb-12">
        <div className="md:w-1/3 py-4 md:py:0">
          <img src={mission} alt="ourMission-img" className="rounded-t-xl" />
          <div className="rounded-b-xl shadow-xl p-6 bg-slate-100 text-justify">
            <p className="font-semibold text-3xl pb-4">Our Mission</p>
            <p>
              At Arka Tech Solution, our mission is to solve our client's most
              pressing challenges through innovative, custom-built product
              solutions. We are dedicated to understanding the unique needs of
              each client and delivering software that not only addresses their
              current problems but also empowers their future growth.
            </p>
          </div>
        </div>
        <div className="md:w-1/3 py-4 md:py:0">
          <img src={vision} alt="ourVision-img" className="rounded-t-xl" />
          <div className="rounded-b-xl shadow-xl p-6 bg-slate-100 text-justify">
            <p className="font-semibold text-3xl pb-4">Our Vision</p>
            <p>
              Our vision is to continuously evolve with the ever-changing
              technology landscape, ensuring that both our team and our clients
              stay ahead of the curve. We are committed to leveraging the latest
              advancements in technology to create opportunities for our
              clients, helping them stay competitive in their respective
              industries.
            </p>
          </div>
        </div>
        <div className="md:w-1/3 py-4 md:py:0">
          <img src={ourValues} alt="ourValues-img" className="rounded-t-xl" />
          <div className="rounded-b-xl shadow-xl p-6 bg-slate-100 text-justify">
            <p className="font-semibold text-3xl pb-4">Our Values</p>
            <p>
              Our values guide every aspect of our work. We are driven by
              innovation, always seeking out new ideas and technologies to craft
              solutions that push boundaries and deliver real results. As a
              women-owned company, we are deeply committed to diversity,
              inclusion, and the empowerment of all voices, creating a
              collaborative environment. We also prioritize continuous learning, staying at the cutting edge of technological advancements to better serve our clients.
            </p>
          </div>
        </div>
      </div>
      <AboutUSTabs />
    </div>
  );
};

export default AboutUS;
