import { useNavigate } from "react-router-dom";

const JobCard = ({ title, location, description }) => {
    const navigate = useNavigate();
    return (
        <>
            <div className="bg-white border border-gray-200 rounded-lg p-5 w-72 text-center shadow-md transition-transform duration-200 hover:scale-105">
                <h3 className="text-xl font-semibold text-blue-600 mb-2">{title}</h3>
                <p className="text-gray-600 mb-2">Location: {location}</p>
                <p className="text-gray-700 mb-4">{description}</p>
                <button className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 transition-colors duration-300" onClick={() => navigate("/contactus")}>
                    Contact US
                </button>
            </div>
        </>
    )
}

export default JobCard;