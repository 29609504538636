import React, { useState, useEffect } from "react";
import { Slide3, Slide4, Slide5, Slide6 } from "../Utils/constants";

const Body = () => {
  const images = [Slide3, Slide4, Slide5, Slide6];
  const [slide, setSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setSlide((prevSlide) => (prevSlide + 1) % images.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <div className="min-h-screen flex flex-col justify-between">
      <div className="space-y-8">
        <div className="flex justify-center">
          <img
            src={images[slide]}
            alt="carousel-slide"
            className="w-[100%] h-[400px] md:h-[500px] object-cover"
          />
        </div>
        <div className="px-8 md:px-16 pt-4 text-lg text-justify">
          <p>
            At Arka Tech Solution, we are more than just a software company—we
            are your strategic partner in innovation. As a women-owned business,
            we take pride in our commitment to excellence, diversity, and
            empowerment. We specialize in creating tailored software products
            that solve real-world problems, driving success for businesses
            across various industries.
          </p>
          <p>
            In a rapidly evolving digital landscape, staying ahead is crucial.
            That’s why we make it our mission to harness the latest
            technologies, offering our clients not just solutions, but a
            competitive edge. Whether you need a custom product designed from
            the ground up or a cutting-edge solution to enhance your existing
            operations, Arka Tech Solution is here to bring your vision to life.
          </p>
        </div>
        <h1 className="font-semibold text-2xl md:text-3xl text-center md:px-[20%] pb-8">
          "Your future, our technology"
        </h1>
      </div>
    </div>
  );
};

export default Body;
