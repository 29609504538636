import React from 'react';
import JobCard from './JobCard';

const Careers = () => {
    return (
        <div className="bg-gray-100 p-5">
            <header className="text-center mb-10 space-y-4">
                <h1 className="text-4xl font-bold text-gray-800 my-6">Join Our Team <span className='hidden md:inline-block'>at ARKA Technologies</span></h1>
                <p className="text-lg text-gray-600 px-8 text-left">At Arka Tech Solutions, we are more than just a software company—we are a team of innovators, problem-solvers, and visionaries dedicated to shaping the future of technology. As a women-owned business, we celebrate diversity, creativity, and the power of collaboration. Our mission is to solve real-world challenges through cutting-edge, custom-built products, and we’re looking for passionate individuals who share our commitment to excellence.   </p>

                <p className="text-lg text-gray-600 px-8 text-left">When you join Arka Tech Solutions, you become part of a dynamic and inclusive workplace where your ideas are valued, your growth is nurtured, and your contributions make a tangible impact. Whether you’re a seasoned professional or just starting your career, we offer a range of opportunities to develop your skills, work on exciting projects, and be part of a team that’s making a difference. </p>  

                <p className="text-lg text-gray-600 px-8 text-left">Explore our current job openings and discover how you can be part of our journey to innovate, inspire, and lead in the tech industry. Your future, our technology—your next big career move starts here. </p>
            </header>
            
            <section className="mb-10 text-center">
                <h2 className="text-3xl font-bold text-gray-800 mb-3">About Us</h2>
                <p className="text-lg text-gray-700 max-w-3xl mx-auto">
                    Arka Technologies is a leading firm in technology solutions, providing top-notch services to clients worldwide. We value creativity, collaboration, and commitment to excellence.
                </p>
            </section>
            
            <section className="text-center my-5">
                <h2 className="text-3xl font-bold text-gray-800 mb-5">Current Openings</h2>
                <div className="flex flex-wrap justify-center gap-5">
                    <JobCard 
                        title="Software Engineer"
                        location="Remote"
                        description="Develop and maintain web applications."
                    />
                    <JobCard 
                        title="Business Development"
                        location="Remote"
                        description="Develop and maintain web applications."
                    />
                    
                    {/* Add more JobCard components as necessary */}
                </div>
            </section>
        </div>
    );
};

export default Careers;