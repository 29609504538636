import React, { useState } from "react";
import { hydMapsLocation, seattleLocation } from "../../../Utils/constants";

const Locations = () => {
    const[mapSrc, setMapSrc] = useState(seattleLocation)
    const locations = [
        { name: "Seattle, WA",map: seattleLocation },
        { name: "Hyderabad, India",map: hydMapsLocation },
    ]
    const handleLocation = (map) => {
        setMapSrc(map);
    }
  return (
    <div className="px-8">
      <div className="space-y-8">
        <p className="md:text-center text-xl font-semibold">Our Locations</p>
        <p className="md:px-40 lg:px-28">
          Our headquarters is based in <span className="font-semibold">Seattle, WA</span>, with a branch offices in
          <span className="font-semibold"> India - Hyderabad</span>. The strategic positioning of our base of operations
          helps our team to effectively coordinate with government and private
          agencies throughout the east coast.
        </p>
        <div className="space-y-4">
        <div className="space-x-4 text-center">
            {locations.map((location)=><button key={location.name} className="bg-blue-200 px-3 py-2 rounded-lg" onClick={()=>handleLocation(location.map)}>{location.name}</button>)}
        </div>
        <div class="w-full h-96 flex items-center bg-gradient-to-r from-black">
          <iframe
            class=" top-0 left-0 w-full h-full"
            src={mapSrc}
            frameborder="0"
            allowfullscreen=""
            aria-hidden="false"
            tabindex="0"
            title="maps"
          ></iframe>
        </div>
        </div>
      </div>
    </div>
  );
};

export default Locations;
